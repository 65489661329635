.HeaderLine {
  width: 80%;
  display: flex;
  margin: auto;
  margin-bottom: 10px;
  height: 1px;
  margin-top: 10px;
  background-color: transparent;
  background-image: radial-gradient(circle, #579250 0, transparent 100%);
}

.CreateButtonBox {
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 90%;
}

.BalanceSection {
  display: flex;
  align-items: center;
  border-radius: 13px;
  width: 90%;
  margin: 5px;
}

.contractAddress {
  box-sizing: border-box;
  width: 12%;
  height: 40px;
}

.DateText {
  display: flex;
  align-items: center;
  text-wrap: nowrap;
  padding-right: 20px;
}

.loadingBox {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 50px;
}

.DepositBoxHeader {
  margin-top: -20px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}

.MainContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 42px 10px;
  gap: 10px;
  background: #151515;
  /* box-shadow: 0px 0px 17.5px 10px rgba(232, 232, 234, 0.37); */
  border-radius: 5px;
  left: calc(50% - 359px / 2 - 189.5px);
  top: 361px;
}

.MainContainerChadPump {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 42px 10px;
  gap: 10px;
  background: #010807;
  /* box-shadow: 0px 0px 17.5px 10px rgba(232, 232, 234, 0.37); */
  border-radius: 5px;
  left: calc(50% - 359px / 2 - 189.5px);
  top: 361px;
  border: solid transparent 1px;
}

.Text1 {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #ffffff;
  flex: none;
  order: 0;
  flex-grow: 0;
}

.MaxButton {
  cursor: pointer;
  margin-top: 0;
}

.CopyButton {
  background: transparent;
  border-radius: 5px;
  border: 0px solid transparent;
  flex: none;
  order: 1;
  flex-grow: 0;
  z-index: 0;
  /* margin: 0 auto; */
  height: 38px;
  color: white;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  cursor: pointer;
}

.CopyButton:hover {
  color: white;
  cursor: pointer;
}

.CreateButton {
  background: #078f34;
  border-radius: 5px;
  border: 0px solid transparent;
  flex: none;
  order: 1;
  flex-grow: 0;
  z-index: 0;
  margin: 0 auto;
  padding: 5px 15px;
  height: 38px;
  color: white;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  margin-top: -25px;
  margin-left: auto;
  float: right;
  width: 95%;
}

.CreateButton:hover {
  color: white;
  cursor: pointer;
}

.CreateButton:active {
  color: white;
  cursor: pointer;
}

.CreateButton:disabled {
  /*background: #c4b7c533;*/
  color: #e6e7e8;
  cursor: unset;
}

.topPeak {
  background: linear-gradient(90deg, rgb(174 255 100) -3.12%, rgb(189 255 105) 29.71%, rgb(150 255 117) 73.6%, rgb(144 255 124) 102.14%) !important;
  border-radius: 10px;
  height: 40px;
  width: 55%;
  background-size: cover;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
  margin-top: 10px;
}

.tooltips {
  position: relative;
  display: inline-block;
}

.tooltips .tooltiptext {
  visibility: hidden;
  width: 200px;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  top: 150%;
  left: -70%;
  margin-left: -84px;

  min-width: 1em;
  min-height: 32px;
  padding: 6px 12px;
  font-size: 14px;
  font-weight: 100;
  line-height: 1.5;
  text-align: start;
  text-decoration: none;
  word-wrap: break-word;
  background-color: rgba(0, 0, 0, 0.85);
  border-radius: 6px;
  box-shadow: 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
  box-sizing: border-box;
}

.tooltips .tooltiptext::after {
  content: "";
  position: absolute;
  bottom: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent black transparent;
}

.tooltips:hover .tooltiptext {
  visibility: visible;
}

.topPeak>p {
  color: #111;
  margin: 0;
  display: flex;
  align-items: center;
}

.HeaderBox {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  max-width: 1200px;
  margin: auto;
}

.HeaderBox>div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  margin-left: 20px;
  margin-right: 20px;
  margin: auto;
  width: 100%;
}

.LockBox {
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: fit-content;
}

.MainDashboard {
  width: 90%;
  margin-left: auto;
  margin-right: auto;
  max-width: 1280px;
  margin-bottom: 10px;
}

.ConnectWalletBox {
  text-align: center;
  height: -webkit-fill-available;
  margin: auto;
  /* margin-bottom: 200px; */
  margin-top: 10%;
  margin-bottom: 20%;
}

.ConnectWalletBoxButton {
  display: flex;
  justify-content: space-evenly;
  background: transparent;
  background: #07100d;
  padding: 5px;
  border-radius: 5px;
  margin-bottom: 20px;
}

.ConnectWalletBoxButton>.claim-button {
  border-radius: 0px;
}

.SpinnerBox {
  display: flex;
  justify-content: space-evenly;
}

.ConnectWalletAgain {
  width: 100%;
  text-align: -webkit-center;
  height: 30%;
  margin-bottom: 30%;
}

.ButtonContainer {
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.ConnectButton {
  width: 25%;
  outline: none;
  border: 0px solid transparent;
  border-image-slice: 1;
  align-items: center;
  font-size: 17px;
  margin-left: -60px;
  margin-bottom: -15px;
  font-weight: 500;
  color: white;
  transition: 0.1s ease-in-out;
  height: 90%;
  cursor: pointer;
  background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.25)),
    linear-gradient(90deg,
      rgb(174, 37, 174) -3.12%,
      rgb(238, 138, 247) 29.71%,
      rgb(210, 71, 203) 73.6%,
      rgb(215, 103, 237) 102.14%) !important;
  backdrop-filter: blur(38.5864px) !important;
  padding: 10px 30px;
  text-align: center;
  transition: 0.5s;
  background-size: 200% auto;
  color: white;
  border-radius: 10px;
  /* text-shadow: 1px 1px 4px black; */
  display: block;
  font-weight: 600;
  transform: scale(1, 1);
}

.navConnectButton {
  width: 184px;
  outline: none;
  border: 0px solid transparent;
  border-image-slice: 1;
  align-items: center;
  font-size: 17px;
  font-weight: 500;
  color: white;
  transition: 0.1s ease-in-out;
  height: 40px;
  margin-top: 10px;
  margin-right: 24px;
  cursor: pointer;
  background: #00a337;
  padding: 10px 10px;
  text-align: center;
  transition: 0.5s;
  background-size: 200% auto;
  color: white;
  border-radius: 10px;
  display: block;
  font-weight: 600;
  transform: scale(1, 1);
}

.ConnectButton:active {
  background-color: transparent;
  color: #26c5eb;
  transform: scale(1);
  cursor: pointer;
}

.ConnectButton:hover {
  background-position: right center;
  background-color: transparent;
  border: 0px solid #04a58a54;
  color: white;
  text-decoration: none;
}

.ConnectButton:disabled {
  background-color: #a6a6a6;
  box-shadow: none;
  cursor: no-drop;
}

.gradient-text {
  background: linear-gradient(90deg, rgb(109, 216, 212) 10%, rgb(2 206 107) 60%) text transparent;
  -webkit-text-fill-color: transparent;
  font-size: 36px;
  font-weight: 700;
}

.GlobalContainer {
  background-size: auto;
  background-position: 0% 0%;
  background-repeat: no-repeat;
  background-size: cover;
  width: auto;
  position: relative;
  height: max-content;
  display: flex;
  flex-direction: column;
  margin-bottom: auto;
  padding-bottom: 350px;
  padding-top: 120px;
  overflow-x: hidden;
  padding-left: 120px;
  overflow-y: hidden !important;
  min-height: 100vh;
}

.inputPanel {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 10px;
}

.inputPanel>p {
  text-wrap: nowrap;
  padding-left: 10px;
  padding-right: 10px;
}

.InputSection {
  width: 45%;
}

.InputSection_Description {
  width: 100%;
}

.inputPanelHeader {
  margin-top: -6px;
  display: flex;
  width: -webkit-fill-available;
  align-items: center;
  background-color: none !important;
}

.InputBox {
  width: 85%;
  display: flex;
  margin: auto;
  justify-content: space-between;
}

.InputContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  box-sizing: border-box;
  background: #151515;
  border: solid transparent 1px;
  border-radius: 5px;
  flex: none;
  flex-grow: 0;
  padding-right: 10px;
}

.InputContainer input::placeholder {
  color: #aaa;
}

.InputContainer:hover {
  border: solid #00a337 1px;
}

.LpInputBox {
  width: 55%;
}

.inputPanel_lp {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */

input[type='number'] {
  -moz-appearance: textfield;
}

.DashboardContainer {
  display: flex;
  width: -webkit-fill-available;
  justify-content: space-between;
  /* height: 50px; */
}

.LpBalance {
  display: flex;
  width: 100%;
  margin-bottom: -5px;
  justify-content: space-between;
}

.sub {
  position: absolute;
  margin-top: 20px;
  margin-left: 3px;
  width: auto;
}

.ContractContentTextTitle {
  font-family: 'Inter';
  text-align: center;
  font-style: normal;
  font-weight: 700;
  line-height: 1.1;
  font-size: 36px;
  background: linear-gradient(to right, #ffffff, #0ca534);
  -webkit-background-clip: text;
  color: transparent;
}

.accountDetail {
  display: flex;
}

.accountData {
  display: flex;
  width: -webkit-fill-available;
  justify-content: space-between;
  flex-direction: column;
}

.withdrawContent {
  width: -webkit-fill-available;
  display: flex;
}

.LoadingBox {
  margin-left: auto;
  margin-right: auto;
  margin-top: 5%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.FirstNote {
  color: #1a449d;
  font-size: 25px;
}

.HeaderText {
  font-size: 20px;
  text-align: initial;
}

.HeaderContent {
  display: flex;
  justify-content: space-between;
  width: -webkit-fill-available;
  color: rgb(196, 196, 196);
  font-size: 14px;
  flex-direction: row;
}

.HeaderContent>p {
  margin: 0px;
}

.HeaderContent2 {
  display: flex;
  justify-content: space-between;
  width: -webkit-fill-available;
  color: white;
  font-size: 20px;
  flex-direction: row;
}

.HeaderContent2>p {
  margin: 5px 0px 5px 0px;
}

.HeaderContent3 {
  width: -webkit-fill-available;
  height: 15px;
  border: 1px solid transparent;
  border-radius: 12px !important;
  background: white;
}

.HeaderContent4 {
  background: linear-gradient(90deg,
      rgb(174, 134, 37) -3.12%,
      rgb(247, 239, 138) 29.71%,
      rgb(210, 172, 71) 73.6%,
      rgb(237, 201, 103) 102.14%) !important;
  height: 15px;
  border-radius: 12px;
}

.HeaderContent5 {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  color: rgb(196, 196, 196);
}

.HeaderContent6 {
  display: flex;
  width: -webkit-fill-available;
  justify-content: space-between;
}

.HeaderContent7 {
  font-size: 14px;
}

.stageBox {
  border-color: rgb(4, 165, 138);
  border-radius: 8px;
  border-width: 1px;
  color: white;
  padding-top: 1rem;
  padding-bottom: 1rem;
  display: flex;
  flex-direction: column;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  margin-bottom: 10px;
}

.starting>p {
  margin: 0px;
  font-weight: 600;
}

.unstarted>p {
  margin: 0px;
  font-weight: 500;
}

.starting {
  background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    linear-gradient(0deg,
      rgb(163, 37, 174) -3.12%,
      rgb(238, 138, 247) 29.71%,
      rgb(199, 71, 210) 73.6%,
      rgb(226, 103, 237) 102.14%) !important;
  backdrop-filter: blur(38.5864px) !important;
  color: white;
  border: 1px solid white;
  text-shadow: 0px 0px 4px black;
}

.unstarted {
  background: transparent;
  color: white;
  border: 1px solid white;
}

.one_how {
  position: relative;
  color: #fff;
  font-weight: 500;
  text-align: center;
  width: 100%;
}

.one_how::before {
  content: '';
  height: 1px;
  position: absolute;
  top: 50%;
  width: 25%;
  background: white;
  left: 0;
}

.one_how::after {
  content: '';
  height: 1px;
  position: absolute;
  top: 50%;
  width: 25%;
  background: white;
  right: 0;
}

.tokensButton {
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  flex-direction: row;
}

.tokenImage {
  width: 35px;
  height: 35px;
  margin-right: 5px;
}

.tokenHeader {
  margin-left: 30px;
  height: 50px;
  padding: 5px;
  padding-top: 5px;
}

.tokenHeaderNoText {
  height: 75%;
}

.tokenButtons {
  display: flex;
  padding: 3px 15px 3px 15px;
  align-items: center;
  border-radius: 10px;
  background-color: transparent;
  color: white;
  border: 0.5px solid white;
  cursor: pointer;
  width: 30%;
  height: 50px;
  justify-content: center;
  font-size: 18px;
}

.HeaderList {
  text-align: initial;
}

.selected {
  border: 0.5px solid white;
  background: rgb(50, 35, 62);
}

.HeaderOfferText {
  text-align: initial;
  font-size: 17px;
}

.HeaderList>li {
  padding-bottom: 5px;
}

.LpDataContainer {
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* background: white; */
  border-radius: 10px;
  border: 1px solid rgba(9, 62, 82, 0.2);
  padding-top: 40px;
  padding-bottom: 40px;
  box-sizing: border-box;
  width: 80%;
  margin-left: auto;
  margin-right: auto;
  padding: 30px;
  text-align: center;
  display: flex;
  justify-content: space-between;
  border: 0.1px solid #555555;
  -webkit-backdrop-filter: blur(20px);
  backdrop-filter: blur(20px);
  margin-bottom: 10px;
}

.ButtonContainer {
  display: flex;
  width: 100%;
}

.connectButtonBox {
  width: 100%;
  display: flex;
  justify-content: center;
}

.ButtonBox {
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 100%;
}

.navConnectButtonBox,
.navConnectButtonMobileBox {
  display: flex;
  justify-content: center;
}

.navConnectButtonMobileBox>section {
  width: -webkit-fill-available;
}

.ChainGroupButton {
  width: 100%;
  display: flex;
  justify-content: space-evenly;
}

.topRightMoonLogo {
  z-index: -1;
  position: absolute;
  width: 300px;
  right: 3000;
  height: 400px;
  top: 50px;
}

.headerMargin {
  margin-bottom: 5px;
}

.h1 {
  font-size: 30px;
  /* text-shadow: 1px 1px 4px black;
  text-shadow: 0px 0px 1px black, 0px 0px 2px black, 0px 0px 4px black; */
}

::placeholder {
  color: rgb(71, 69, 69);
}

.tokenLists {
  font-size: 14px;
  color: #00a337;
}

.chatLists {
  font-size: 14px;
  color: white;
  margin: 5px;
}

.chatContent {
  display: flex;
  align-items: center;
  cursor: pointer;
  flex-direction: row-reverse;
  justify-content: flex-end;
}

.Metamask {
  cursor: pointer;
  width: 25px;
  height: 25px;
}

.TokenAdder {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  padding-left: 5px;
  padding-right: 5px;
}

.tooltip {
  position: relative;
  display: inline-block;
  padding: 9px 0;
  width: 100%;
}

.tooltiptext {
  color: white;
  background-color: transparent;
}

.Balance-header-container {
  margin-left: 30px;
}

.trade-box {
  max-width: 800px;
  min-width: 280px;

  flex: none;
  order: 2;
  flex-grow: 0;
  padding-top: 0px;
  padding-bottom: 0px;
  padding-left: 20px;
  padding-right: 20px;
  background-position: top center;
  background-repeat: no-repeat;
  background-size: 100% 120px;
  margin: auto;
  margin-top: 20px;
  display: flex;
  border-bottom: 1px solid #817c7c;
}

.Trade-token-name {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 24px;
  color: #ffffff;
  text-align: center;
  margin-top: 12px;
  margin-bottom: 8px;
  text-transform: uppercase;
}

.balance-token-logo {
  border: 1px solid transparent;
  border-radius: 50%;
  width: 50px;
  height: 50px;
}

.balanceContent {
  display: flex;
  align-items: center;
  width: -webkit-fill-available;
}

.tooltip {
  visibility: hidden;
  background-color: #555;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 15px;
  position: absolute;
  z-index: 1;
  bottom: 125%;
  left: 50%;
  transform: translateX(-50%);
  opacity: 0;
  transition: opacity 0.3s;
}

.tooltip .tooltiptext::after {
  content: '';
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #555 transparent transparent transparent;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}

.claimHeaderBox {
  margin-left: 20px;
  width: -webkit-fill-available;
  padding-top: 5px;
}

.claimHeaderContentBox {
  display: flex;
  align-items: center;
  width: -webkit-fill-available;
}

@media screen and (max-width: 1600px) {
  .withdrawContent {
    flex-direction: column;
  }


  .launches-switch-all {
    width: calc(100% - 344px);
  }
}

@media screen and (max-width: 1050px) {
  .withdrawContent {
    flex-direction: column;
  }

  .navConnectButtonBox>.navConnectButton {
    display: none;
  }
}

@media screen and (max-width: 640px) {
  .GlobalContainer {
    padding: 0px;
  }

  .footer {
    flex-direction: column;
  }

  .BalanceSection {
    width: auto;
  }

  .tokenButtonText {
    font-size: 16px;
  }

  /* .navWallet {
    display: none;
  } */

  .navConnectButton {
    width: 130px;
    padding: 8px;
    font-size: 14px;
  }
}

@media screen and (max-width: 420px) {
  .tokenButtonText {
    font-size: 12px;
  }

  .h1 {
    font-size: 24px;
  }

  .ContractContentTextTitle {
    margin: auto;
    margin-bottom: 20px;
  }

  .ChainGroupButton {
    display: flex;
    flex-direction: column;
    margin: auto;
    width: auto;
    justify-content: space-around;
    align-items: center;
  }
}

@media screen and (max-width: 480px) {
  .navLink {
    display: none;
  }

  .tokenHeader {
    height: 30px;
    margin-right: 3px;
  }

  .tokenLists {
    font-size: 11px;
  }
}

@media screen and (min-width: 480px) {
  .tokenHeaderNoText {
    display: none;
  }
}

@media screen and (max-width: 370px) {
  .tokenButtonText {
    display: none;
  }
}

@media screen and (max-width: 800px) {
  .tokensButton {
    width: 100%;
  }

  .ConnectButton {
    width: 140px;
    margin-bottom: 10px;
  }

  .HeaderContent6 {
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-around;
  }

  .InputSection {
    width: 100%;
  }

  .contractAddress {
    width: auto;
  }

  .inputPanel {
    flex-direction: row;
    width: 100%;
  }

  .inputPanelHeader {
    flex-direction: row;
    width: 100%;
  }

  .DashboardContainer {
    width: 90%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
}

.FAQ {
  max-width: 800px;
  width: 800px;
  margin: auto;
  position: relative;
  z-index: 1;
}

.ResponsiveFlexLayout {
  display: flex;
  justify-content: center;
  width: 1280px;
  max-width: 1280px;
}

.RevokeTokenSelect {
  width: 570px;
}

.claim-eth-token-logo {
  visibility: visible;
  height: 22px;
  display: flex;
  width: 22px;
  align-items: center;
  color: blue;
  cursor: pointer;
  padding-left: 10px;
}

.LpTokenSelect {
  width: 285px;
  margin-right: -55px;
}

.BurnLpTokenSelect {
  width: -webkit-fill-available;
}

.BuyMax {
  position: absolute;
  top: -14px;
  left: 225px;
  color: #aba9ac;
  cursor: pointer;
}

.holderBox {
  display: flex;
  width: auto;
  justify-content: space-between;
}

.holderBox>p {
  color: white;
  margin: 0px;
}

.tradeBox>div {
  width: 20%;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.tradeBox {
  display: flex;
  width: auto;
  justify-content: space-between;
}

.chatBox>div {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.chatBox {
  display: flex;
  width: auto;
  justify-content: space-between;
  flex-direction: column;
}

.tradeBox>p {
  color: white;
  width: 20%;
  display: flex;
  justify-content: center;
}

.SendButton {
  background: #00a337;
  border-radius: 5px;
  border: 0px solid transparent;
  /* flex: none; */
  /* order: 1; */
  /* flex-grow: 0; */
  /* z-index: 0; */
  width: 100%;
  margin: 0 auto;
  height: 38px;
  color: white;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  cursor: pointer;
}

.holderContent {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  cursor: pointer;
}

.chartTab_outer {
  width: 100%;
  height: 400px;
  z-index: 1;

  background: lightsteelblue;
}

.chartTab_container {
  width: 100%;
  height: 100%;
  /* padding: 1%; */

  background-color: white;
}

.chart {
  width: -webkit-fill-available;
  height: 400px;
}

.holderContent>svg {
  color: white;
  width: 15%;
}

.createChadFlexLayout {
  width: 1280px;
  max-width: 1280px;
  margin: auto;
}

.EmptyLaunchpad {
  margin-bottom: 25%;
}

.ClaimBox {
  width: -webkit-fill-available;
  margin-left: 32px;
  margin-right: 32px;
}

@media (max-width: 1500px) {
  .ResponsiveFlexLayout {
    display: flex;
    justify-content: center;
    width: 1280px;
    max-width: 1280px;
  }

  .createChadFlexLayout {
    width: 1200px;
    max-width: 1200px;
  }

  .LpTokenSelect {
    width: 250px;
  }

  .RevokeTokenSelect {
    width: 500px;
  }
}

@media (max-width: 1400px) {
  .createChadFlexLayout {
    width: 860px;
    max-width: 860px;
  }
}

@media (max-width: 1340px) {
  .ResponsiveFlexLayout {
    display: flex;
    justify-content: center;
    width: 1024px;
    max-width: 1024px;
  }

  .LpTokenSelect {
    width: 193px;
  }

  .RevokeTokenSelect {
    width: 386px;
  }
}

@media (max-width: 1280px) {
  .ResponsiveFlexLayout {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 1024px;
    width: 90%;
  }

  .ClaimBox {
    margin-left: 0px;
    margin-right: 0px;
  }

  .BuyMax {
    top: -14px;
    left: 350px;
  }

  .LpTokenSelect {
    width: 198px;
  }

  .RevokeTokenSelect {
    width: 396px;
  }

  .ExchangeForm {
    padding-top: 0px;
  }

  .topRightMoonLogo {
    height: 200px;
    width: 200px;
  }

  .topRightMoonLogo {
    display: none;
  }
}

@media (max-width: 1024px) {
  .createChadFlexLayout {
    width: 800px;
    max-width: 800px;
  }
}

@media (max-width: 950px) {
  .createChadFlexLayout {
    width: 420px;
    max-width: 420px;
  }
}

@media (max-width: 900px) {
  .FAQ {
    max-width: 480px;
    width: 480px;
  }
}

@media (max-width: 800px) {
  .BuyMax {
    top: -32px;
    left: 350px;
  }

  .ClaimBox {
    margin-left: 0px;
    margin-right: 0px;
  }

  .claimHeaderBox {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
}

@media (max-width: 560px) {
  .ResponsiveFlexLayout {
    width: 90%;
    max-width: 90%;
  }


  .BuyMax {
    top: -32px;
    left: 220px;
  }

  .LpTokenSelect {
    width: 137px;
  }

  .RevokeTokenSelect {
    width: 274px;
  }

  .FAQ {
    max-width: 360px;
    width: 360px;
  }
}

@media (max-width: 480px) {
  .BuyMax {
    top: -32px;
    left: 280px;
  }

  .createChadFlexLayout {
    width: 360px;
    max-width: 360px;
  }
}

@media (max-width: 420px) {

  .createChadFlexLayout {
    width: 280px;
    max-width: 280px;
  }

  .BuyMax {
    top: -32px;
    left: 230px;
  }

  .LpTokenSelect {
    width: 112px;
  }

  .RevokeTokenSelect {
    width: 224px;
  }

  .FAQ {
    max-width: 300px;
    width: 300px;
  }

  .launches-switch {
    width: 100% !important;
  }
}

.LeftColumn {
  width: 50%;
  padding: 1rem;
  margin: 0 16px;
  margin-bottom: 32px;
}

@media (max-width: 1280px) {
  .LeftColumn {
    width: 100%;
    order: 0;
    max-width: 90%;
  }
}

.RightColumn {
  width: 70%;
  padding: 1rem;
  margin: 0 16px;
  margin-bottom: 32px;
}

@media (max-width: 1280px) {
  .RightColumn {
    width: 90%;
  }
}

.h2 {
  font-size: 18px;
  color: white;
  /* text-shadow: 1px 1px 4px black; */
  /* text-shadow: 0px 0px 1px black, 0px 0px 2px black, 0px 0px 4px black; */
}

.text {
  font-size: 18px;
}

.YouTube {
  width: 100%;
  margin-top: 50px;
  aspect-ratio: 16 / 9;
  border-radius: 10px;
  box-shadow: 0px 0px 8px 4px rgba(88, 255, 255, 0.1);
}

.navBar {
  height: 64px;
  /*background: #000428;*/
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 24px 0 30px;
  /*border-bottom: 1px solid #2a2b77;*/
  /*box-shadow: 0px 4px 8.2px 2px #4646aa;*/
}

.topBar {
  position: absolute;

  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;

  color: #ffffff;
}

.countdownHeader {
  outline: none;
  align-items: center;
  font-size: 17px;
  font-weight: 500;
  transition: 0.1s ease-in-out;
  cursor: pointer;
  background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.25)),
    linear-gradient(90deg,
      rgb(174, 37, 174) -3.12%,
      rgb(238, 138, 247) 29.71%,
      rgb(210, 71, 203) 73.6%,
      rgb(215, 103, 237) 102.14%) !important;
  backdrop-filter: blur(38.5864px) !important;
  padding: 5px;
  text-align: center;
  transition: 0.5s;
  background-size: 200% auto;
  color: white;
  /* text-shadow: 1px 1px 4px black; */
  display: block;
  font-weight: 600;
}

.countdownBigText {
  font-size: 20px;
  font-weight: 1000;
  margin-right: 3px;
}

.countdownSmallText {
  font-size: 12px;
  margin-right: 12px;
}

.navLink {
  color: white;
  padding: 20px;
  text-decoration: none;
}

.footer {
  text-align: center;
  justify-content: space-evenly;
  background: #010404;
  align-items: center;
  justify-content: space-between;
  padding: 0;
  padding-left: 20px;
  padding-right: 20px;
  margin: 0;
  padding-bottom: 24px;
  text-align: center;
  margin-top: -20px;
}

.footerText {
  font-size: 16px;
  text-align: center !important;
  color: white;
  margin: auto;
}

body {
  background: #151515;
}

.countdownDescription {
  margin-right: 10px;
  font-size: 18px;
  font-weight: 600;
}

.countdown {
  background: #00a337;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 4px 4px;
  margin: 4px 4px;
  border-radius: 5px;
  width: 27px;
  height: 24px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  color: #ffffff;
  flex: none;
  order: 0;
  flex-grow: 0;
}

.countdown-sub {
  font-size: 1rem;
  color: #aaa;
  text-align: center;
}

p {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #ffffff;
  flex: none;
  order: 1;
  flex-grow: 0;
  /* text-shadow: 0px 0px 1px black, 1px 1px 2px black, 2px 2px 4px black; */
}

li {
  margin-bottom: 16px;
  /* text-shadow: 0px 0px 1px black, 1px 1px 2px black, 2px 2px 4px black; */
}

li:last-child {
  margin-bottom: 0;
}

h3 {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  color: #ffffff;
  text-align: center;
  /* text-shadow: 0px 0px 1px black, 0px 0px 2px black, 0px 0px 4px black; */
}

.GlobalContainer::before {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #010404;
  z-index: 0;
}

h4 {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 29px;
  color: #ffffff;
  text-align: center;
}

.gradientSeparator {
  width: 100%;
  margin: auto;
  position: relative;
  z-index: 1;
}

.gradientSeparator::before {
  content: '';
  position: absolute;
  top: -48px;
  left: 0;
  width: 100%;
  height: 48px;
  background: linear-gradient(to bottom,
      rgba(0, 4, 40, 0) 0%,
      rgba(0, 4, 40, 1) 100%);
  z-index: 1;
  /* Make sure it's on top of the GlobalContainer */
}

.textContainer {
  padding-left: 24px;
  padding-right: 24px;
}

a {
  color: white;
}

hr {
  opacity: 0.5;
}

.faqTitle {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.faqAnswer {
  margin-top: -16px;
  /* opacity: 0.5; */
}

.fas.fa-chevron-down {
  transition: transform 0.2s ease-in-out;
  color: #ffffff;
  margin-left: 10px;
}

.rotate {
  transform: rotate(90deg);
}

@font-face {
  font-family: 'Inter';
  src: url('../fonts/Inter-Medium.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Bauhaus93';
  src: url('../fonts/Bauhaus-93-400.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

.chadfun {
  font-family: 'Bauhaus93';
  font-size: '36px';
}

.launchpad-card {
  max-width: 280px;
  width: 280px;
  min-width: 280px;
  /* height: 520px; */
  background: #020a08;
  /* box-shadow: 0px 0px 17.5px 10px rgba(232, 232, 234, 0.37); */
  border-radius: 10px;
  flex: none;
  border: solid transparent 1px;
  order: 2;
  flex-grow: 0;
  padding-top: 20px;
  padding-bottom: 20px;
  padding-left: 20px;
  padding-right: 20px;
  margin: auto;
  background-position: top center;
  background-repeat: no-repeat;
  background-size: 100% 120px;
}

.launchpad-status-icon {
  width: 5px;
  height: 5px;
  left: 8px;
  top: 5px;
  padding-right: 4px;
  padding-bottom: 8px;
}

.launchpad-status-text {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
  display: flex;
}

.launchpad-status-sale-live {
  padding: 2px;
  width: 69px;
  height: 15px;
  background: #b6c7ff;
  border-radius: 4px;
  color: #291ce6;
  display: flex;
  align-items: center;
  justify-content: center;
}

.launchpad-status-upcoming {
  padding: 2px;
  width: 69px;
  height: 15px;
  background: #ffd9b6;
  border-radius: 4px;
  color: #e6951c;
  display: flex;
  align-items: center;
  justify-content: center;
}

.launchpad-status-closed {
  padding: 2px;
  width: 56px;
  height: 15px;
  background: #ffb6b6;
  border-radius: 4px;
  color: #f50a0a;
  display: flex;
  align-items: center;
  justify-content: center;
}

.launchpad-badge-rug-proof {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 10px;
  width: fit-content;
  height: 30px;
  background: #00a337;
  border-radius: 5px;
  color: white;
  padding-left: 5px;
  padding-right: 5px;
  margin: 4px;
  width: 60px;
}

.launchpad-badge-all-in {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 10px;
  width: fit-content;
  height: 30px;
  background: #69d338;
  border-radius: 5px;
  color: white;
  padding-left: 5px;
  padding-right: 5px;
  margin: 4px;
  width: 60px;
}

.launchpad-badge-doxed {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 10px;
  width: fit-content;
  height: 30px;
  background: #a907f5;
  border-radius: 5px;
  color: white;
  padding-left: 5px;
  padding-right: 5px;
  margin: 4px;
  width: 60px;
}

.launchpad-badge-kyc {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 10px;
  width: fit-content;
  height: 30px;
  background: #ff9a02;
  border-radius: 5px;
  color: white;
  padding-left: 5px;
  padding-right: 5px;
  margin: 4px;
  width: 60px;
}

@media screen and (max-width: 480px) {
  .launchpad-card {
    padding-left: 5px;
    padding-right: 5px;
  }

  .launchpad-badge-rug-proof {
    padding-left: 5px;
    padding-right: 5px;
    margin: 4px;
  }

  .launchpad-badge-all-in {
    padding-left: 5px;
    padding-right: 5px;
    margin: 4px;
    width: 50px;
  }

  .launchpad-badge-doxed {
    padding-left: 5px;
    padding-right: 5px;
    margin: 4px;
    width: 50px;
  }

  .launchpad-badge-kyc {
    padding-left: 5px;
    padding-right: 5px;
    margin: 4px;
    width: 50px;
  }
}

.lanchpad-eth-logo {
  width: 150px;
  height: 150px;
}

.launchpad-token-name {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  color: #ffffff;
  text-align: center;
  margin-top: 12px;
  margin-bottom: 8px;
  text-transform: uppercase;
}

.launchpad-cap-type {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  text-align: center;
  margin-left: -6px;
  color: #ffffff;
}

.lauchpad-cap-limit {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  color: red;
  text-align: center;
  margin-top: -10px;
}

.launchpad-addresses {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: white;
}

.launchpad-address {
  display: flex;
  flex-direction: column;
  width: -webkit-fill-available;
}

.left-aligned {
  flex: 1;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

a {
  text-decoration: none;
}

.CopyButton:hover {
  text-decoration: underline;
}

.center-aligned {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.right-aligned {
  flex: 1;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.launchpad-badge-text {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 10px;
  line-height: 12px;
  color: #ffffff;
  flex: none;
  order: 0;
  flex-grow: 0;
}

.launchpad-progress-text {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 12px;
  color: #ffffff;
  margin-top: 10px;
  margin-bottom: 10px;
}

.launchpad-balance-text {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  color: #777;
  margin-top: 30px;
  margin-bottom: 0px;
}

.launchpad-liquidity-text {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 15px;
  color: #291ce6;
  margin-top: 0px;
  margin-bottom: 2px;
}

.launchpad-progress-endpoints {
  display: flex;
  justify-content: space-between;
}

.launchpad-lockup-time-row {
  display: flex;
  justify-content: space-between;
}

.launchpad-progress-container {
  width: 100%;
}

.launchpad-info-container {
  display: flex;
  justify-content: space-between;
}

.commuity-progress-container {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.commuity-progress-container>a {
  text-decoration: blink;
}

.launchpad-lockup-time-text {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 12px;
  color: #ffffff;
  margin-top: 3px;
  margin-bottom: 3px;
}

.launchpad-sale-starts-text {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #ffffff;
}

.launchpad-sale-starts-timer {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  color: #ffffff;
}

.launchpad-details-button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 1px 32px;
  gap: 10px;
  width: 100%;
  height: 29px;
  left: 230px;
  top: 433px;
  background: #00a337;
  border-radius: 5px;
  text-decoration: auto;
}

.launchpad-details-button-text {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  color: #ffffff;
  flex: none;
  order: 0;
  flex-grow: 0;
}

.launchpad-sale-starts-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.launchpad-bottom-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 70px;
}

.launchpad-hr {
  padding-top: 8px;
  padding-bottom: 8px;
}

.create-token-button {
  padding: 12px 32px;
  background: #00a337;
  border-radius: 1rem;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;
  text-decoration: none;
  color: #ffffff;
}

.launches-switch {
  box-sizing: border-box;
  max-width: 1100px;
  margin: auto;
  border-radius: 5px;
  padding: 16px 32px;
  width: calc(100% - 120px);
}

.launches-switch-all {
  box-sizing: border-box;
  background: #030606;
  max-width: 1255px;
  margin-left: 13px;
  border: 1px solid #00a337;
  border-radius: 5px;
  padding: 16px 32px;
  width: calc(100% - 120px);
}

.launches-filter-bar-dropdown select {
  width: 120px;
  padding: 8px 16px;
  color: white;
  background-color: #030606;
  border: solid #00a337 1px;
  border-radius: 4px;
  cursor: pointer;
  margin-right: 15px;
  margin-top: 15px;
}

.launches-filter-bar-dropdown select:focus {
  outline: none;
}

.launches-filter-bar-dropdown option:checked {
  background-color: #00a337;
}

.ConnectWalletButtonFirst {
  width: max-content;
  margin-left: auto;
  margin-right: auto;
  font-size: xx-large;
  margin-top: 70px;
}

.launches-switch-active {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  border-bottom: 3px solid;
  padding-bottom: 7px;
  border-color: #00a337;
  border-radius: 1px;
  line-height: 24px;
  color: #ffffff;
  text-decoration: none;
  margin: 0px 16px;
}

.launches-switch-passive {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  color: #888888;
  text-decoration: none;
  margin: 0px 16px;
}

.launches-switch-passive:hover {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  /* border-bottom: 3px solid;*/
  padding-bottom: 7px;
  border-color: #00a337;
  border-radius: 1px;
  line-height: 24px;
  color: #00a337;
  text-decoration: none;
  margin: 0px 16px;
}

@media screen and (max-width: 480px) {
  .launches-switch-passive {
    font-size: 13px;
    margin: 0px 4px;
  }

  .launches-switch-active {
    font-size: 13px;
    margin: 0px 4px;
    padding-bottom: 11px;
  }

  .create-token-button {
    padding: 8px 16px;
    background: #00a337;
    border-radius: 5px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
    text-decoration: none;
    color: #ffffff;
  }
}

.launchpad-card-grid {
  display: grid;
  gap: 50px 16px;
  grid-template-columns: repeat(auto-fill, minmax(370px, 1fr));
  max-width: 1152px;
  margin: auto;
  justify-items: stretch;
}

.launchpad-eth-logo-container {
  display: flex;
  justify-content: left;
  width: 100%;
  margin: auto;
  border-radius: 50%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.claim-eth-logo-container {
  display: flex;
  justify-content: center;
  width: min-content;
  margin: 0;
  border-radius: 0%;
}

@media (max-width: 480px) {
  .launchpad-card-grid {
    grid-template-columns: repeat(auto-fill, minmax(100%, 1fr));
    margin-bottom: 50px;
  }

  .launchpad-card {
    padding-left: 5px;
    padding-right: 5px;
  }
}

@media (max-width: 340px) {
  .launchpad-card {
    margin-left: -6px;
  }
}

.fairlaunch-token-info {
  text-align: left;
  width: 90%;
  /* margin-left: 32px; */
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  color: #00a337;
}

.fairlaunch-allocation-buttons-container {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
}

.fairlaunch-allocation-button {
  background: #00a337;
  border-radius: 18px;
  border: solid 0px transparent;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  padding: 4px 8px;
  color: #ffffff;
  cursor: pointer;
}

.fairlaunch-date-time {
  position: relative;
  border: 1px solid #000;
  border-radius: 4px;
  padding: 16px;
}

.calendar-icon {
  position: absolute;
  top: 7px;
  top: 50%;
  transform: translateY(-50%);
  right: 10px;
}

@media (max-width: 400px) {
  .calendar-icon {
    display: none;
  }
}

.fairlaunch-date-time-wrapper {
  position: relative;
}

.react-datepicker__input-container {
  width: inherit;
}

.react-datepicker-wrapper {
  width: 100%;
  padding-right: 34px;
}

.fairlaunch-date-time-wrapper,
.fairlaunch-date-time-wrapper>div.react-datepicker-wrapper,
.fairlaunch-date-time-wrapper>div>div.react-datepicker__input-container .fairlaunch-date-time-wrapper>div>div.react-datepicker__input-container input {
  width: 100%;
}

.claim-card {
  /* max-width: 800px;
  min-width: 280px; */
  /* box-shadow: 0px 0px 17.5px 10px rgba(232, 232, 234, 0.37); */
  border-radius: 5px;
  flex: none;
  order: 2;
  flex-grow: 0;
  padding-top: 20px;
  padding-bottom: 20px;
  padding-left: 20px;
  padding-right: 20px;
  margin: auto;
  background-color: #020a08;
  background-position: top center;
  background-repeat: no-repeat;
  background-size: contain;
  border: solid transparent 1px;
  margin-top: 10px;
}

.claim-card-empty {
  max-width: 800px;
  min-width: 280px;
  border-radius: 5px;
  flex: none;
  order: 2;
  flex-grow: 0;
  padding-top: 20px;
  padding-bottom: 20px;
  padding-left: 20px;
  padding-right: 20px;
  margin: auto;
  background-position: top center;
  background-repeat: no-repeat;
  background-size: contain;
  border: solid transparent 1px;
}

.claim-card-empty-header {
  max-width: 800px;
  min-width: 280px;
  border-radius: 5px;
  flex: none;
  order: 2;
  flex-grow: 0;
  padding-top: 20px;
  padding-bottom: 0px;
  padding-left: 50px;
  padding-right: 20px;
  margin: auto;
  background-position: top center;
  background-repeat: no-repeat;
  background-size: contain;
  border: solid transparent 1px;
}

@media screen and (max-width: 480px) {
  .claim-card {
    padding-left: 5px;
    padding-right: 5px;
  }
}

.claim-eth-logo {
  border: 1px solid transparent;
  border-radius: 50%;
  width: 200px;
  height: 200px;
  background-size: 100%;
  max-width: 200px;
  background-position: center top;
  background-repeat: no-repeat;
  overflow-x: hidden;
  border-radius: 5px;
}

.claim-card-logo {
  border: 2px solid black;
  border-radius: 50%;
  width: 54px;
  height: 54px;
  position: absolute;
  top: 64px;
  left: 0;
}

.launchpad-blockchain-logo {
  filter: drop-shadow(0px 0px 2px #000);
  border-radius: 50%;
  position: absolute;
  top: -16px;
  right: -16px;
  width: 32px;
  height: 32px;
}

.social-section {
  position: absolute;
  top: 72px;
  right: -10px;
}

.relative {
  position: relative;
}

.absolute {
  position: absolute;
}

.claim-token-name {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 28px;
  line-height: 24px;
  color: #ffffff;
  text-align: center;
  margin-top: 24px;
  margin-bottom: 16px;
  text-transform: uppercase;
}

.claim-cap-type {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  text-align: center;
  color: #ffffff;
}

.claim-cap-limit {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  color: #3e54a0;
  text-align: center;
  margin-top: -10px;
}

.claim-progress-text {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
  color: #ffffff;
  margin-top: 3px;
  margin-bottom: 3px;
}

.claim-progress-bar {
  width: 100%;
  height: 13px;
  background: #dcd7d7;
  border-radius: 4px;
}

.claim-progress-bar-filled {
  height: 13px;
  background: #00a337;
  border-radius: 4px;
}

.claim-liquidity-text {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: #291ce6;
  margin-top: 0px;
  margin-bottom: 2px;
}

.claim-progress-endpoints {
  display: flex;
  justify-content: space-between;
}

.claim-lockup-time-row {
  display: flex;
  justify-content: space-between;
}

.claim-progress-container {
  width: 100%;
}

.claim-lockup-time-text {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
  color: #ffffff;
  margin-top: 3px;
  margin-bottom: 3px;
}

.claim-sale-starts-text {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #ffffff;
}

.claim-sale-starts-timer {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  color: #ffffff;
}

.claim-details-button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 1px 32px;
  gap: 10px;
  width: 90px;
  height: 29px;
  left: 230px;
  top: 433px;
  background: #00a337;
  border-radius: 5px;
}

.claim-details-button-text {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  color: #ffffff;
  flex: none;
  order: 0;
  flex-grow: 0;
}

.claim-sale-starts-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.claim-bottom-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.claim-hr {
  padding-top: 8px;
  padding-bottom: 8px;
  width: 100%;
}

.claim-header {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  color: #ffffff;
}

.claim-text,
.fair-launch-info-label,
.fair-launch-info-value,
.token-info-label,
.token-info-value {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 19px;
  color: #777 !important;
}

.token-info-label-header {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #ffffff;
  display: flex;
}

.token-info-label-header>p {
  margin-right: 20px;
  cursor: pointer;
  padding-bottom: 7px;
}

.token-info-item,
.fair-launch-info-item {
  display: flex;
  justify-content: space-between;
}

.chat-eth-logo-container {
  display: flex;
  align-items: center;
}

.chat-profile-avatar {
  position: inherit;
  width: 40px;
  height: 40px;
}

.chatContent>p {
  margin: 0px;
}

@media screen and (max-width: 480px) {

  .claim-text,
  .fair-launch-info-label,
  .fair-launch-info-value,
  .token-info-label,
  .token-info-value {
    font-size: 12px;
    line-height: 14px;
  }

  .claim-card {
    padding-left: 5px;
    padding-right: 5px;
    margin-left: 10px;
    margin-right: 10px;
  }
}

.token-info-address {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  margin-left: 5px;
  color: #00a337;
  word-break: break-all;
  max-width: 100%;
  cursor: pointer;
}

.token-info-address-warning {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #fd2525;
}

.token-info-subitem {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  text-align: right;
}

.avoid-scam {
  margin-top: -50px;
  box-sizing: border-box;
  background: #00a337;
  border: 1px solid #ffffff;
  width: 100%;
}

.avoid-scam-text {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  text-align: center;
  color: #ffffff;
  width: 100%;
}

.ClaimLeftColumn {
  width: 45%;
  padding: 1rem;
  margin: 0 16px;
  margin-bottom: 32px;
}

@media (max-width: 1280px) {
  .ClaimLeftColumn {
    width: 100%;
    order: 0;
    max-width: 100%;
  }

  .claim-eth-logo-container {
    display: flex;
    justify-content: center;
  }
}

.ClaimRightColumn {
  width: 65%;
  padding: 1rem;
  margin: 0 16px;
  margin-bottom: 32px;
}

@media (max-width: 1280px) {
  .ClaimRightColumn {
    width: 100%;
  }
}

.switch-button {
  background: #00a337;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  text-align: center;
  color: #ffffff;
  padding: 8px 16px;
  width: -webkit-fill-available;
  cursor: pointer;
  border: 1px solid transparent;
  border-radius: 5px;
}

.claim-button {
  margin-top: 8px;
  background: #00a337;
  border-radius: 5px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  text-align: center;
  color: #ffffff;
  padding: 8px 8px;
  width: -webkit-fill-available;
  cursor: pointer;
  border: 1px solid transparent;
}

.claim-button:disabled {
  background: #00a337;
}

.claim-button:hover {
  background: #00a337;
}

.listSelected {
  color: white;
  background-color: #00a337;
  padding: 5px;
  border-radius: 5px;
}

.top-bar {
  position: absolute;
  top: 58px;
  width: calc(100%);
  height: 64px;
  display: flex;
  background-color: #010404;
  align-items: center;
}

.top-bar .rocket {
  width: 0px;
  height: 0px;
  transform: matrix(-1, 0, 0, 1, 0, 0);
  flex: none;
  flex-grow: 0;
  margin-left: 60px;
}

.top-bar-text {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  color: #ffffff;
  text-decoration: auto;
}

.top-bar-text2 {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  color: #b6c7ff;
  flex: none;
  flex-grow: 0;
  margin-right: 3%;
}

.moon {
  position: absolute;
  width: 180px;
  height: 180px;
  right: 72px;
  top: 143px;
  filter: drop-shadow(0px 0px 10px rgba(255, 255, 255, 0.75));
}

.left-bar {
  position: fixed;
  left: 0;
  top: 140px;
  width: 100vw;
  min-height: calc(100vh - 60px);
  background: #030606;
  height: 100%;
  backdrop-filter: blur(32px);
  z-index: 3;
  overflow: hidden;
  transition: width 0.3s ease, opacity 0.3s ease-in-out;
  border-top: 1px solid #00a337;
  border-right: 1px solid #00a337;
}

.left-bar-menu {
  list-style-type: none;
  padding: 0;
  margin-right: 32px;
  display: block;
  width: 48px !important;
  height: 48px !important;
  flex: none;
  flex-grow: 0;
  margin-left: 0px;
  margin-top: 10px;
  z-index: 4;
  display: none;
  cursor: pointer;
}

.left-bar-links {
  list-style-type: none;
  padding: 0;
  margin: 0;
  margin-left: 0;
  margin-top: 0px;
  overflow: hidden;
  transition: opacity 0.3s ease-in-out;
}

.left-bar-icon {
  width: 25px;
  height: 25px;
  margin-right: 5px;
  margin-top: 8px;
  margin-bottom: 8px;
}

.left-bar-text {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 1.25rem;
  transition: opacity 0.3s ease;
  display: block;
  opacity: 1;
  transition: opacity 0.2s ease-in-out;
}

.left-bar-text-active {
  opacity: 1;
  display: block;
}

.left-bar-hr {
  color: white;
  width: 90%;
}

.left-bar {
  width: 100vw;
}

.left-bar:hover .left-bar-text {
  visibility: visible;
  opacity: 1;
}

.left-bar-link {
  display: flex;
  flex-direction: column;
  align-items: left;
  text-decoration: none;
  padding: 8px;
  margin: 4px;
}

.left-bar-link:hover {
  background-color: #ffffff33;
  border-radius: 10px;
}

.GlobalContainer {
  padding-left: 0px;
}

.left-bar {
  visibility: hidden;
}

@media screen and (max-width: 1024px) {
  .left-bar {
    width: 0px;
  }

  .left-bar-menu {
    display: block;
  }

  .top-bar {
    width: calc(100%);
  }
}

.left-bar-expanded {
  visibility: visible;
  opacity: 1;
  width: 100vw;
}

.left-bar:not(.left-bar-expanded):hover .left-bar-text {
  visibility: visible;
  opacity: 1;
}

.fairlaunch-date-time {
  border: solid transparent 1px !important;
}

.upload-box {
  width: 100%;
  min-width: 175px;
  max-width: 175px;
  height: 175px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #151515;
  border: solid transparent 1px;
  border-radius: 5px;
  cursor: pointer;
  flex-grow: 1;
  flex-basis: 0;
}

.upload-box:hover {
  border: solid #00a337 1px !important;
}

.upload-box-banner {
  width: 100%;
  min-width: 175px;
  max-width: 350px;
  height: 175px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #151515;
  border: solid transparent 1px;
  border-radius: 5px;
  cursor: pointer;
  flex-grow: 1;
  flex-basis: 0;
}

.upload-box:hover {
  border: 1px solid #aaa;
}

.upload-icon {
  width: 24px;
  height: 24px;
  fill: currentColor;
  margin-bottom: 4px;
}

.upload-text {
  margin-top: 12px;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji',
    'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
  color: rgba(232, 230, 227, 0.3) !important;
  font-size: 12px;
  font-weight: 600;
  color: #ffffff;
}

.profile-upload-text {
  font-family: 'Inter';
  font-style: normal;
  font-size: 16px;
  line-height: 19px;
  color: #ffffff;
  padding: 20px;
  background: #151515;
  border-radius: 5px;
}

.preview-image {
  max-width: 100%;
  max-height: 100%;
  display: block;
  object-fit: contain;
  width: 175px;
  margin-top: 10px;
}

.InputSectionLogo {
  width: 30%;
}

.InputSectionBanner {
  width: 60%;
}

@media screen and (max-width: 1280px) {
  .InputSectionLogo {
    width: 30%;
  }

  .InputSectionBanner {
    width: 50%;
  }

  .launches-switch-active {
    border-bottom: 0px;
  }
}

@media screen and (max-width: 800px) {
  .InputSectionLogo {
    width: 100%;
  }

  .InputSectionBanner {
    width: 100%;
  }
}

.upload-button {
  padding: 8px 16px;
  background-color: #0099;
  color: white;
  border: none;
  cursor: pointer;
}

.upload-button:hover {
  background-color: #005f;
}

.social-icon {
  width: 28px;
  height: 28px;
  margin: 0 5px;
}

.loadMoreText {
  text-align: center;
  margin-top: 100px;
  font-size: larger;
  margin-bottom: 0px;
  cursor: pointer;
}

.edit-profile-button {
  box-sizing: border-box;
  background: #151515;
  border: solid transparent 1px;
  border-radius: 5px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  padding: 5px;
  padding-left: 10px;
  padding-right: 10px;
  color: #ffffff;
  text-decoration: auto;
}

.save-button {
  display: flex;
  align-items: center;
  margin: auto;
  cursor: pointer;
  background: #00a337;
  border: 1px solid transparent;
  color: white;
  padding: 5px 10px;
  border-radius: 5px;
  font-size: 18px;
}

.profile-wallet-address {
  box-sizing: border-box;
  background: #151515;
  border: solid transparent 1px;
  border-radius: 5px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  color: #ffffff;
  padding: 10px;
  width: 90%;
}

.profile-text-input {
  box-sizing: border-box;
  background: #151515;
  border: solid transparent 1px;
  border-radius: 5px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  color: #ffffff;
  padding: 20px;
  width: 470px;
  max-width: 100%;
}

.profile-logo-input,
.profile-banner-input {
  box-sizing: border-box;
  background: #020a08;
  border: solid transparent 1px;
  border-radius: 5px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  color: #ffffff;
  padding: 32px;
  width: 470px;
  max-width: 100%;
  padding-top: 5px;
  margin-right: 8px;
}

@media (max-width: 550px) {
  .profile-text-input {
    width: 320px;
  }

  .claimHeaderContents {
    flex-direction: column;
  }
}



.profile-user-details-wrapper {
  display: grid;
  grid-template-columns: 1fr 3fr;
  gap: 10px;
  margin-left: 50px;
  margin-top: 25px;
  align-items: center;
}

@media (max-width: 768px) {
  .profile-user-details-wrapper {
    grid-template-columns: 1fr;
  }
}

.profile-text {
  margin-top: 17px;
}

.user-details-wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: fit-content;
  margin: auto;
}

.profile-user-avatar-wrapper {
  position: absolute;
  top: 0px;
  left: -120px;
}

@media screen and (max-width: 640px) {
  .profile-user-avatar-wrapper {
    position: relative;
    top: 0px;
    left: 0px;
    margin-top: 25px;
  }
}

.balances-box {
  max-width: 800px;
  min-width: 280px;
  background: #020a08;
  border-radius: 10px;
  flex: none;
  border: solid transparent 1px;
  order: 2;
  flex-grow: 0;
  padding-top: 0px;
  padding-bottom: 0px;
  padding-left: 20px;
  padding-right: 20px;
  background-position: top center;
  background-repeat: no-repeat;
  background-size: 100% 120px;
  margin: auto;
  margin-top: 20px;
  display: flex;
}

.balances-box>a {
  width: 100%;
}

.trades-box {
  max-width: 800px;
  min-width: 280px;
  flex: none;
  order: 2;
  flex-grow: 0;
  padding-top: 0px;
  padding-bottom: 0px;
  padding-left: 20px;
  padding-right: 20px;
  background-position: top center;
  background-repeat: no-repeat;
  background-size: 100% 120px;
  margin: auto;
  display: flex;
  border-bottom: 1px solid #5d5a5a;
}

.trades-box>a {
  width: 100%;
}

.Balance-token-name {
  color: rgba(243, 242, 248, 0.5);
}

.trade-header-container {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.balanceContent>p {
  margin-top: 5px;
  margin-bottom: 5px;
}

.profile-user-avatar {
  /* border-radius: 9999px; */
  overflow: hidden;
}

.profile-user-avatar-image {
  max-width: 96px;
  max-height: 96px;
}

.profile-user-bio {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  color: #ffffff;
}

.profile-user-followers {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  color: #ffffff;
}

.profile-text {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  color: #ffffff;
}

.profile-user-name {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  color: #ffffff;
}

.tokenHeader1 {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.top-trending {
  display: flex;
  align-items: center;
}

.buys {
  border-top-right-radius: 0.75rem;
  border-bottom-right-radius: 0.75rem;
  background-color: #aba9ac;
  padding: 14px;
}

.right-triangle {
  clip-path: polygon(0 0, 75% 0, 100% 50%, 75% 100%, 0 100%, 0 0);
}

.top-header {
  position: fixed;
  display: flex;
  flex-direction: row;
  width: 100vw;
  left: 0;
  top: 0;
  background-color: #010404;
  height: 60px;
  z-index: 10;
}

.logo-header {
  display: flex;
  flex-direction: row;
}

.top-bar-item {
  text-wrap: nowrap;
  overflow: hidden;
}

.top-bar-item-box {
  display: flex;
}

.RefBox {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.top-bar-item {
  display: flex;
  flex-direction: row;
  margin-left: 10px;
  align-items: center;
  border-right: 1px solid #9e4f4f;
}

.top-bar-address {
  margin-left: 5px;
  color: #aaaaaa;
  text-decoration: none;
  display: flex;
}

.top-bar-description {
  margin-left: 5px;
  color: white;
}

.top-bar-token {
  margin-top: 5px;
  margin-left: 5px;
  color: #fd8f92;
  font-weight: bold;
}

.top-bar-token-icon {
  margin-left: 5px;
  margin-right: 10px;
}

.logo-text {
  margin-top: 10px;
  margin-left: 10px;
}

.hide-icon {
  display: none;
}

.custom-radio-button-wrapper {
  box-sizing: border-box;
  width: fit-content;
  object-fit: contain;
  border-radius: 5px;
  padding: 6px 12px;
  margin: auto;
  display: flex;
  flex-direction: row;
  gap: 12px;
  margin-bottom: 20px;
}

.custom-radio-button-wrapper2 {
  box-sizing: border-box;
  width: fit-content;
  object-fit: contain;
  border-radius: 5px;
  padding: 6px 12px;
  margin: auto;
  display: flex;
  flex-direction: row;
  gap: 12px;
}

.custom-radio-button-unselected {
  display: flex;
  align-items: center;
  cursor: pointer;
  background: transparent !important;
  border: solid 1px transparent;
  color: #92959c;
  padding: 5px 10px;
  border-radius: 5px;
  font-size: 18px;
}

.custom-radio-button-selected {
  display: flex;
  align-items: center;
  cursor: pointer;
  background: transparent;
  border: 1px solid transparent;
  border-left: 4px solid #00a337;
  color: white;
  padding: 5px 10px;
  border-radius: 5px;
  font-size: 18px;
}

.right-panel {
  position: absolute;
  top: 60px;
  right: 0;
  flex: 1;
  min-height: calc(100% - 129px);
  margin-left: 15px;
  border: 1px solid #00a337;
  padding: 10px;
  height: 100%;
  background-color: #030606;
  min-width: 320px;
}

.right-panel-container {
  display: flex;
  flex-direction: column;
}

@media (max-width: 1024px) {
  .right-panel {
    display: none;
  }

  .launches-all-padding {
    padding-right: 0px;
  }
}

.trophy {
  top: -32px;
  left: -5px;
  filter: drop-shadow(4px 4px 8px #000);
}

.profile-card {
  max-width: 800px;
  min-width: 280px;
  overflow: hidden;
  background: #020a08;
  /* box-shadow: 0px 0px 17.5px 10px rgba(232, 232, 234, 0.37); */
  border-radius: 10px;
  flex: none;
  border: solid transparent 1px;
  order: 2;
  flex-grow: 0;
  padding-top: 20px;
  padding-bottom: 20px;
  padding-left: 20px;
  padding-right: 20px;
  background-position: top center;
  background-repeat: no-repeat;
  background-size: 100% 120px;
  margin: auto;
  margin-top: 0px;
  margin-bottom: 32px;
}

.profile-card-header {
  max-width: 800px;
  min-width: 280px;
  border-radius: 10px;
  flex: none;
  border: solid transparent 1px;
  order: 2;
  flex-grow: 0;
  padding-top: 20px;
  padding-bottom: 20px;
  padding-left: 20px;
  padding-right: 20px;
  background-position: top center;
  background-repeat: no-repeat;
  background-size: 100% 120px;
  margin: auto;
  margin-top: 80px;
}

.profile-avatar {
  filter: drop-shadow(0px 0px 2px #000);
  border-radius: 50%;
  width: 80px;
  height: 80px;
}

.profile-name {
  display: flex;
  flex-direction: row;
  margin-left: 0px;
  padding-top: 50px;
  margin-top: -60px;
  font-size: 1.25rem;
}

.profile-edit {
  display: flex;
  flex-direction: row;
  margin-left: 8px;
}

.profile-item {
  display: flex;
  flex-direction: row;
}

.profile-title {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  color: #ffffff;
  margin-left: 8px;
  margin-bottom: -10px;
  width: 100%;
}

.file-input {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  position: absolute;
  z-index: -1;
}

.custom-file-input-container {
  cursor: pointer;
  border: solid transparent 1px;
  background: #020a08;
  padding: 0px;
  border-radius: 5px;
  margin-bottom: 20px;
  width: 100%;
}

.custom-file-input {
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: center;
  gap: 5px;
}

.custom-file-input input[type='file'] {
  position: absolute;
  top: 0;
  right: 0;
  opacity: 0;
  width: 100%;
  height: 100%;
}

.profile-logo-input-button,
.profile-banner-input-button {
  background: #00a337;
  border: 1px solid transparent;
  color: white;
  padding: 5px 10px;
  border-radius: 5px;
  font-size: 18px;
  cursor: pointer;
  width: 135px;
}

.hidden {
  display: none;
}

.profile-logo-input-text,
.profile-banner-input-text {
  cursor: pointer;
  background: none;
  border: 1px solid transparent;
  color: #fff;
  padding: 5px 10px;
  border-radius: 5px;
  font-size: 18px;
  flex-grow: 1;
  outline: none;
}

@media screen and (max-width: 1024px) {
  .nav-links {
    display: none !important;
  }

  .custom-radio-button-wrapper {
    border: 1px solid transparent;
    padding: 0;
    gap: 6px;
  }

  .profile-card {
    padding-top: 20px;
    padding-bottom: 20px;
    padding-left: 4px;
    padding-right: 4px;
    margin-left: 4px;
    margin-right: 4px;
  }

  .navConnectButtonBox>.navConnectButton {
    display: none;
  }

  .buys {
    margin-left: 60px;
  }

  .left-bar {
    margin-left: -1px;
  }

  .footer {
    padding-left: 20px;
  }
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.fair-launch-box {
  border: 1px solid #00a337;
  background-color: #030606;
  border-radius: 5px;
  padding: 10px;
  margin-right: 10px;
  color: white;
}

.fair-launch-container {
  display: flex;
  justify-content: space-between;
}

.fair-launch-container-mobile {
  display: none;
}

.createdBy>.CopyButton {
  margin-left: 10px;
}

.fair-launch-label {
  color: lightgray;
}

.claimHeaderContentsBox {
  display: flex;
  width: -webkit-fill-available;
}


@media screen and (max-width: 1024px) {
  .logo-text {
    visibility: hidden;
  }

  .fair-launch-container {
    display: none;
  }

  .fair-launch-container-mobile {
    display: flex;
    flex-direction: column;
    width: -webkit-fill-available;
    background-color: #151515;
    margin: 10px 0;
    border-radius: 5px;
    border-color: transparent;
    display: flex;
    flex-direction: column;
    padding: 12px;
  }

  .fair-launch-box {
    margin: auto;
    margin-bottom: 10px;
    min-width: 200px;
    display: flex;
    justify-content: space-between;
  }

  .claimHeaderContentsBox {
    flex-direction: column;
    margin-top: 20px;
  }
}

.launchpad-card {
  max-width: 285px;
  width: 285px;
  min-width: 285px;
  height: auto;
  background: #030606;
  border-radius: 10px;
  border: 1px solid rgb(1, 255, 217, 0.4);
  padding: 20px;
  margin: auto;
  overflow: hidden;
  box-sizing: border-box;
  /* Ensure padding does not affect width */
}

.launchpad-card:hover {
  border-color: #00a337;
}

.relative {
  position: relative;
}

.launchpad-banner {
  width: 283px;
  height: 240px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  overflow: hidden;
  margin-left: -20px;
  margin-right: -20px;
  margin-top: -20px;
}

.launchpad-change-tag {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 21px;
  right: 0px;
  color: rgb(17, 17, 17);
  font-family: 'PingFang SC', sans-serif;
  font-size: 12px;
  font-weight: 500;
  height: 24px;
  box-shadow: rgba(14, 30, 30, 0.5) 0px 2px 10px;
  gap: 4px;
  border-radius: 20px 0px 0px 20px;
  border-width: initial;
  border-style: none;
  border-color: initial;
  border-image: initial;
  padding: 0px 6px 0px 10px;
}

.launchpad-header-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 10px;
}

.launchpad-created-by {
  color: rgb(255, 255, 255);
  font-family: 'PingFang SC', sans-serif;
  font-weight: 500;
  font-size: 12px;
  margin-right: 5px;
}

.launchpad-dev-address {
  font-weight: 500;
  line-height: 16.8px;
  font-size: 12px;
  color: rgb(130, 255, 152);
  text-decoration: underline;
}

.launchpad-social-section {
  display: flex;
  align-items: center;
}

.launchpad-social-icon {
  width: 12px;
  height: 12px;
  margin-left: 15px;
  opacity: 0.5;
  transition: opacity 0.3s ease, transform 0.3s ease;
  position: relative;
  z-index: 999;
}

.launchpad-social-icon:hover {
  opacity: 1;
  transform: scale(1.33);
  /* 16px / 12px = 1.33 */
}

.launchpad-dev-name-token-name {
  color: rgb(255, 255, 255);
  font-family: 'PingFang SC', sans-serif;
  font-weight: 600;
  font-size: 14px;
  line-height: 19.6px;
  margin-top: 10px;
}

.launchpad-description {
  font-weight: 400;
  font-size: 12px;
  line-height: 16.8px;
  color: rgb(153, 153, 153);
  width: 245px;
  height: 68px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 4;
  text-overflow: ellipsis;
  overflow: hidden;
  font-family: 'PingFang SC', sans-serif;
}

.launchpad-market-cap {
  font-family: 'PingFang SC', sans-serif;
  font-size: 12px;
  font-weight: 500;
  line-height: 16.8px;
  color: rgb(153, 153, 153);
  margin-top: 15px;
}

.launchpad-progress-container {
  width: 100%;
  border-radius: 100px;
  margin-top: 10px;
}

.launchpad-progress-bar-unfilled {
  height: 8px;
  background: #2b2b35;
  border-radius: 100px;
  transition: all 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
}

.launchpad-progress-bar {
  height: 8px;
  background: linear-gradient(to right, rgb(105, 1, 255), rgb(255, 117, 163));
  border-radius: 100px;
  transition: all 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
}

.leunchpad-sort-options {
  border: 1px solid rgb(1, 255, 217, 0.4);  
}

.leunchpad-sort-options:hover {
  border: solid 1px #00a337 !important;
}

@keyframes scroll {
  0% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(calc(-100%));
  }
}

.scroll-container {
  overflow: hidden;
  white-space: nowrap;
  position: relative;
  width: 100%;
}

.scroll-items {
  display: inline-block;
  animation: scroll 20s linear infinite;
}

.scroll-item {
  display: inline-block;
  margin-right: 10px;
}

.scroll-items.paused {
  animation-play-state: paused;
}

.text-gray {
  font-size: 14px;
  font-weight: 600;
  font-family: 'PingFang SC', sans-serif;
  color: #7c7b81;
}

.tokenBuy {
  color: rgb(53, 255, 254);
}

.tokenSell {
  color: rgb(255, 74, 138);
}

.tokenAmount {
  color: rgb(255, 255, 255);
}

.tokenDate {
  color: rgb(255, 255, 255);
}

.rounded-lg {
  border-radius: 0.5rem;
}

.bg-primary {
  background-color: #151515;
}

.text-secondary {
  color: #777777;
}

.border-primary {
  border-color: #09371e;
}

.bg-active {
  background-color: #00a337;
}

.text-white {
  color: white;
}

.bg-transparent {
  background-color: rgba(255, 255, 255, 0.2);
}

.px-2 {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

.py-1 {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}

.mx-1 {
  margin-left: 0.25rem;
  margin-right: 0.25rem;
}

.border {
  border-width: 1px;
  border-style: solid;
}

.fairLaunchBoxStyle {
  background-color: #151515;
  margin: 10px 0;
  border-radius: 5px;
  border-color: transparent;
  display: flex;
  flex-direction: column;
  padding: 12px;
  width: 15%;
}

@media screen and (max-width: 1024px) {
  .fairLaunchMobileBoxStyle {
    background-color: #151515;
    margin: 0px 0;
    border-radius: 5px;
    border-color: transparent;
    display: flex;
    align-items: center;
  }
}

.buy-back-button {
  max-width: 800px;
  margin: auto;
}

.back-button {
  font-size: 20px;
  color: #92959c;
  background: none;
  border: none;
}

.copied-popup {
  position: absolute;
  top: 50px;
  left: 50%;
  transform: translateX(-50%);
  background: #151515;
  color: white;
  border: 1px solid #4c00cb;
  padding: 10px;
  border-radius: 5px;
  font-size: 14px;
  z-index: 1000;
  pointer-events: none;
}

.claim-token-content-box {
  display: flex;
  align-items: center;
}

.claim-token-content-box>p {
  margin: 0px;
}

.claim-token-name>p {
  margin: 0px;
}

.InputSection_Description>p {
  color: grey;
  font-size: 14px;
}

@media (max-width: 768px) {
  .launchpad-eth-logo-container {
    flex-direction: column;
    align-items: flex-start;
  }

  .CreateButton {
    float: none;
    width: 85%;
    margin-top: 1rem;
  }

  .profile-card-header .profile-address {
    font-size: 16px;
  }

  .copied-popup {
    display: block;
    margin-left: 8px;
  }

  .profile-card {
    height: 180px;
  }
}

.launchpad-card-horizontal {
  max-width: 600px;
  height: auto;
  background: #030606;
  border-radius: 10px;
  border: 1px solid rgb(1, 255, 217, 0.4);
  display: flex;
  align-items: center;
  padding: 0px;
  padding-right: 20px;
  margin: auto;
  overflow: hidden;
  box-sizing: border-box;
}

.HeaderBox>div>.h1 {
  font-size: 48px;
  font-family: ping-fang;
  margin-top: 5px;
  margin-bottom: 15px;
}

.chadContent {
  font-size: 20px;
  font-weight: 600;
  line-height: 28px;
}

.howitworks {
  font-family: ping-fang;
  font-weight: 600;
  font-size: 20px;
  line-height: 28px;
  color: #00a337;
  text-decoration: underline;
  padding: 0;
  margin: 0px;
  white-space: nowrap;
  cursor: pointer;
}

.launchpad-card-horizontal:hover {
  border-color: #00a337;
}

.launchpad-banner-horizontal {
  width: 240px;
  height: 200px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  overflow: hidden;
  margin-right: 20px;
}

.launchpad-content {
  flex-grow: 1;
}

.launchpad-description-horizontal {
  width: 320px;
}

.MainContainerChadPump>div {
  display: flex;
  flex-direction: row;
  width: 89%;
}

.MainContainerChadPump>div>section {
  margin-right: 32px;
}

.createdBy {
  color: #777;
  font-weight: bold;
  margin-right: 32px;
}

.claimHeaderContents {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.claimHeaderBox>p {
  font-size: 14px;
  color: #999;
}

@media (max-width: 768px) {

  .launchpad-card-horizontal {
    display: flex;
    flex-direction: column;
    width: 290px;
  }

  .launchpad-banner-horizontal {
    width: 300px;
    height: 240px;
    margin-right: 0px;
  }

  /* .launchpad-card-horizontal-header {
    display: none;
  } */
  .HeaderBox {
    flex-direction: column;
    margin-top: 130px;
  }

  .topPeak {
    width: auto;
  }

  .HeaderBox>div>p {
    text-align: center;
    margin: auto;
    margin-top: 10px;
  }

  .logo-header {
    align-items: center;
  }

  .MainContainerChadPump>div {
    flex-direction: column;
    display: flex;
    align-items: center;
    width: -webkit-fill-available;
  }

  .MainContainerChadPump>div>section {
    margin-right: 0px;
  }

  .Text1 {
    display: flex;
    flex-direction: column;
  }

  .claim-eth-logo {
    width: 130px;
    height: 150px;
  }

  .claimHeaderContentBox {
    align-items: flex-start;
  }

  .createdBy {
    margin-left: 0px;
  }

  .claim-token-content-box {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .claim-token-name {
    margin-top: 0px;
  }

  .navConnectButtonBox>section {
    display: none;
  }

  .HeaderBox>div>.h1 {
    font-size: 40px;
  }

  .HeaderBox>div {
    align-items: center;
  }

  .ProfileBox {
    margin-bottom: 50px;
  }
}

@media (max-width: 440px) {
  .profile-text-input {
    width: 280px;
  }

  .claimHeaderContentBox {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .claimHeaderBox {
    margin-left: 0px;
    align-items: center;
  }

  .tooltips .tooltiptext {
    margin-left: -200px;
  }
}